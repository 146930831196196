.team-list{
    float: left;
    width: 95%;
    height: 80%;
    overflow: visible;

    padding-left: 2%;
    padding-top: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    box-sizing: border-box;
  }

.team{
    box-sizing: border-box;
    overflow: visible;
    float: left;
    height: 100%;
    position: relative;
}

.team .inner{
    border-radius: 5px;
    height: 100%;
    padding: 1%;
    box-sizing: border-box;
    width: 98%;
    background: rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.team .divider{
    border-top: 1px solid rgba(255,255,255,0.5);
    width: 95%;
    margin: 0 -1% 0 0;
    float: right;
}

.team .teamleader{
    margin: 0 auto;
    position: absolute;
    top: 0.5vh;
    left: 0.5vh;
}

.team .teamleader .person{
    float: none;
}

.team h3.team-name{
    margin: 1vh;
    text-shadow: 1px 1px 2px black;
    text-align: right;
    height: 2em;
    max-width: calc(100% - 4.8vw);
    float: right;
}

.team .team-description{
    margin: 0.9vh 0 0 0;
    padding-right: 1vh;
    text-shadow: 1px 1px 2px black;
    text-align: right;
    float: right;
    width: 100%;
    /* overflow: hidden; */
    height: 3vh;
    font-size: 0.9vw;
    font-weight: normal;
    line-height: 0.9vw;
}

.team .members{
    margin-top: 4.3vw;
    padding-right: 0.2vw;
}

.subteam .members{
    margin-top: 0.5vw;
}

.team .subteam .divider{
    width: 100%;
    margin: 0 0 0 0;
    float: right;
}