.pause-screen-img {
    width: 100%;
}

.pause-text-box {
    position: absolute;
    z-index: 1;
    width: 25vw;
    right: 0;
    padding: 1.5vw;
    text-align: center;
}