.unit-booking-menu-box {
    position: fixed;
    
    left: 50%;
    top: 45%;
    z-index: 100;
    height: 9vh;
    text-align: center;
}
.unit-booking-delete-button, .unit-booking-edit-button, .confirm-delete-button {
    color: rgb(242 11 11 / 70%);
    font-family: inherit;
    font-weight: 600;

    width: 15vh;
    border-radius: 1vh;
    z-index: 102;
    position: relative;
    border-color: white;
}
.unit-booking-menu-buttons {
    display: flex;
    justify-content: space-evenly;
}